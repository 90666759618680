import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/working_dir/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import Component from 'react-component-component';
import { DEFAULT_RECENT_PRODUCT_SEARCHES } from '../../src/RecentProductSearches/context';
import { fakeProductSearchResults } from '../../src/ProductSearchModal/fakeProductSearchData';
import { Button } from '../../src';
import RecentSearches from '../../src/RecentProductSearches';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ProductSearchModal = makeShortcode("ProductSearchModal");
const RecentProductSearches = makeShortcode("RecentProductSearches");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "recentsearches"
    }}>{`RecentSearches`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import ProductSearchModal from '@mfrm/mfcl/ProductSearchModal'
import RecentSearches from '@mfrm/mfcl/RecentSearches'
`}</code></pre>
    <h2 {...{
      "id": "description"
    }}>{`Description`}</h2>
    <p>{`The ProductSearch components is a dialog that is used to display a list of products based on a search query. The RecentSearches component is used to display a list of recent searches.`}</p>
    <p>{`If used with NextJS, leverage dynamic import for modal. Such as,`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const DynamicProductSearchDialog = dynamic(() => import('@mfrm/mfcl/ProductSearchModal'), { ssr: false })
`}</code></pre>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={RecentSearches} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic Usage`}</h2>
    <p>{`Usage with Product Search and Recent Searches.`}</p>
    <Playground __position={1} __code={'<Component initialState={{ isOpen: false }}>\n  {({ setState, state }) => (\n    <div>\n      <ProductSearchModal\n        enableFakeData\n        fakeSearchData={fakeProductSearchResults}\n        isOpen={state.isOpen}\n        onClose={isOpen => setState({ isOpen: false })}\n      />\n      <Button\n        btnType=\"primary\"\n        onClick={() => setState({ isOpen: !state.isOpen })}\n        size=\"lg\"\n      >\n        Open Modal\n      </Button>\n    </div>\n  )}\n</Component>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      DEFAULT_RECENT_PRODUCT_SEARCHES,
      fakeProductSearchResults,
      Button,
      RecentSearches,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Component initialState={{
        isOpen: false
      }} mdxType="Component">
    {({
          setState,
          state
        }) => <div>
        <ProductSearchModal enableFakeData fakeSearchData={fakeProductSearchResults} isOpen={state.isOpen} onClose={isOpen => setState({
            isOpen: false
          })} mdxType="ProductSearchModal" />
        <Button btnType="primary" onClick={() => setState({
            isOpen: !state.isOpen
          })} size="lg" mdxType="Button">
          Open Modal
        </Button>
      </div>}
  </Component>
    </Playground>
    <h2 {...{
      "id": "usage-with-recent-searches"
    }}>{`Usage with Recent Searches`}</h2>
    <p>{`Usage with Product Search and Recent Searches.`}</p>
    <Playground __position={2} __code={'<Component initialState={{ isOpen: false }}>\n  {({ setState, state }) => (\n    <div>\n      <ProductSearchModal\n        enableFakeData\n        fakeSearchData={fakeProductSearchResults}\n        isOpen={state.isOpen}\n        onClose={isOpen => setState({ isOpen: false })}\n      />\n      <Button\n        btnType=\"primary\"\n        onClick={() => setState({ isOpen: !state.isOpen })}\n        size=\"lg\"\n      >\n        Open Modal\n      </Button>\n      <RecentProductSearches\n        enableFakeData\n        fakeRecentSearches={DEFAULT_RECENT_PRODUCT_SEARCHES}\n      />\n    </div>\n  )}\n</Component>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      DEFAULT_RECENT_PRODUCT_SEARCHES,
      fakeProductSearchResults,
      Button,
      RecentSearches,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Component initialState={{
        isOpen: false
      }} mdxType="Component">
    {({
          setState,
          state
        }) => <div>
        <ProductSearchModal enableFakeData fakeSearchData={fakeProductSearchResults} isOpen={state.isOpen} onClose={isOpen => setState({
            isOpen: false
          })} mdxType="ProductSearchModal" />
        <Button btnType="primary" onClick={() => setState({
            isOpen: !state.isOpen
          })} size="lg" mdxType="Button">
          Open Modal
        </Button>
        <RecentProductSearches enableFakeData fakeRecentSearches={DEFAULT_RECENT_PRODUCT_SEARCHES} mdxType="RecentProductSearches" />
      </div>}
  </Component>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      